import ModalHelperController from './modal_helper'
import { useClickOutside } from 'stimulus-use'
import { FetchRequest } from '@rails/request.js'

// Connects to data-controller="basic-modal"
export default class extends ModalHelperController {
  static values = {
    isDismissible: Boolean,
    dismissPath: String
  }

  static targets = ['confirmButton']

  connect() {
    this.hideScrollbar()
    this.focusModal() // required for esc button to work
    useClickOutside(this)
  }

  clickOutside(event) {
    if (event.target.dataset.modalNoClose === 'true' || event.target.parentElement.dataset.modalNoClose === 'true') {
      // check for data-modal-no-close="true" on current target or direct parent
      // currently used in _flash_messages.html.erb
      return null
    } else {
      this.dismiss(event)
    }
  }

  // Makes a turbo stream request to a provided path
  // expects a turbo stream response that will close the modal
  dismiss(event) {
    if (event.type === 'keydown' && event.key === undefined) {
      // return if we have a weird Chrome password manager autofill event which is closing the modal for no reason
      // Probably related to: https://github.com/hotwired/stimulus/pull/713
      return
    }
    event.preventDefault()
    this.showScrollbar()

    if (this.isDismissibleValue) {
      if (this.dismissPathValue.length > 0) {
        const request = new FetchRequest('post', this.dismissPathValue, { responseKind: 'turbo-stream' })
        request.perform()
      } else {
        this.element.closest('.basic-modal').remove()
      }
    }
  }

  handleConfirmation(event) {
    const confirmationCheckbox = event.target
    if (confirmationCheckbox.checked) {
      this.confirmButtonTarget.dataset.disabled = 'false'
    } else {
      this.confirmButtonTarget.dataset.disabled = 'true'
    }
  }
}
